import React from 'react';

const Wave = ({ color = '#8668FC' }: { color?: string }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='147'
			height='31'
			fill='none'
		>
			<path
				fill={color}
				fillRule='evenodd'
				d='m141 15.1-.2.9a11.6 11.6 0 0 1-7.8 8.2 11.3 11.3 0 0 1-11-2.5 12 12 0 0 1-3.4-5.7l-.3-.8-.2-1.4c-.6-2.7-1.7-5.2-3.4-7.4l-.7-.7-.2-.3-.7-.6-.1-.2-.5-.4-1.3-1A16.7 16.7 0 0 0 85 13.3v.4l-.3 1.4-.2.9a11.6 11.6 0 0 1-7.8 8.2 11.3 11.3 0 0 1-11-2.5 12 12 0 0 1-3.5-5.7L62 15h-.1l-.3-1.3c-.5-2.7-1.7-5.2-3.4-7.4l-.6-.7-.3-.3-.6-.6-.2-.2-.4-.4-1.3-1a16.7 16.7 0 0 0-26.2 10.1v.3l-.1.1-.3 1.4-.3.9a11.6 11.6 0 0 1-7.8 8.2 11.3 11.3 0 0 1-11-2.5A12 12 0 0 1 5.7 16l-.3-.9H0l.3 1.4c.5 2.7 1.7 5.2 3.4 7.3l.6.8.3.3.6.6.2.2.4.3 1.3 1a16.7 16.7 0 0 0 26.2-10v-.4h.1l.3-1.4.3-.9a11.6 11.6 0 0 1 7.8-8.2 11.3 11.3 0 0 1 11 2.5 12 12 0 0 1 3.4 5.7l.3.8.3 1.4a17.3 17.3 0 0 0 4 8l.3.4.6.6.2.1.5.4c.4.4.8.8 1.3 1a16.7 16.7 0 0 0 26.1-10l.1-.4.3-1.5.2-.8a11.6 11.6 0 0 1 7.8-8.2 11.3 11.3 0 0 1 11 2.5 12 12 0 0 1 3.5 5.7l.2.8.3 1.4c.5 2.7 1.7 5.2 3.4 7.3l.6.8.3.3.6.6.2.2.4.3 1.3 1a16.7 16.7 0 0 0 26.2-10v-.4l.4-1.5H141ZM1.8 17.4Zm3.5 6.4Zm2 1.9ZM60.5 14Zm-.3-1.2v.2-.3.1Zm-3.4-6.3Zm1.4 11v-.1.2-.2Zm43.3-16.3Zm15.2 11.6v.1-.2.1Zm-3-5.6-.5-.7.6.7Zm.9 10.2Zm2.9 5.7Zm4.7 4Zm7.4 2Z'
				clipRule='evenodd'
			/>
		</svg>
	);
};

export default Wave;
